import { useCookies } from "vue3-cookies";

import { environmentFromOrigin, setupConfig } from "@/config";
import telemetry from "@/utils/telemetry";

export default async function setSessionId() {
  await setupConfig(environmentFromOrigin(), true);

  const cookies = useCookies().cookies;
  // check for session-id cookie or create one with window.crypto.randomUUID that expires in 120 days
  if (!cookies.get("session-id")) {
    cookies.set("session-id", window.crypto.randomUUID(), "120d");
    if (!cookies.get("x-lacp-secure") && !cookies.get("x-lacp-profile")) {
      telemetry.sessionCreation(
        "Home",
        "Home",
        "all",
        "abcE2B3d344zSC2eZSJmdef",
        true
      );
    }
  }
}
