import { z } from "zod";

import http from "@/services/http";

const topic = z.object({
  name: z.string().optional(),
  value: z.string().optional(),
  id: z.number().optional(),
});

const factor = z.object({
  type: z.string(),
  interestedTopics: z.array(topic),
});

const object = z.object({
  objectId: z.string(),
  objectTitle: z.string(),
  type: z.string(),
});

const requiredObject = z.object({
  action: z.string(),
  factor: z.array(factor),
  object: object,
});

export type RequiredObject = z.infer<typeof requiredObject>;

export function capture(reqObj: RequiredObject) {
  return http()
    .post(window.env.TELEMETRY_MIDDLEWARE_API_LOCATION, reqObj)
    .catch((err) => {
      console.error("Error capturing telemetry", err);
    });
}
